import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Intro from "./Components/Hero/Intro";
import Services from "./Components/Services/Services";
import Experience from "./Components/Experience/Experience";
import Work from "./Components/Work/Work";
import Portfolio from "./Components/Portfolio/Portfolio";
import Testmonial from "./Components/Testmonials/Testmonial";
import ContactUs from "./Components/ContactUs/ContactUs";
import Footer from "./Components/Footer/Footer";
import { themeContext } from "./Context";
import { useContext } from "react";


function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <div
      className="App"
      style={{
        background: darkMode? 'black' : '',
        color: darkMode? 'white' : '',
      }}
    >
      <Navbar />
      <Intro />
      <Services />
      <Experience />
      <Work />
      <Portfolio />
      <Testmonial />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
