import React from 'react'
import './Card.css'

const Card = ({emoji, heading, detail}) => {
  return (
    <>
      <div className="cards">
       <img src={emoji} alt="" /> 
        <h3>{heading}</h3>
        <p>{detail}</p>
        <button className="c-btn">LEARN MORE</button>
      </div>
    </>
  )
}

export default Card
