import React from 'react'
import './Floating.css'

import { themeContext } from "../../Context";
import { useContext } from "react";

const Floating = ({image , text1, text2}) => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <>
      <section className="float">
        <img src={image} alt="" />
        <span style={{
          // background: darkMode ? "black" : "",
          color: darkMode ? "black" : "",
        }}>
          {text1}
          <br/>
            {text2}
        </span>
      </section>
      
    </>
  )
}

export default Floating
