import React from 'react'
import './Testmonial.css'
import one from '../../img/one.jpg'
import two from '../../img/two.jpg'
import three from '../../img/three.jpg'
import four from '../../img/four.jpg'
import five from '../../img/five.jpg'
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';



const Clients =[
  
  {
  img:five,
  review: 'Very good understanding of the requirements in our project. Open to new technologies and timely delivery.'
},
  {
  img:two,
  review: 'Very fast delivery! He understands the requirements quickly and works very independently. Nice job!'
},
  {
  img:three,
  review: 'This is my second gig and he has been outstanding as the last time...I am sure I will work with him in the future and advise everyone to confidently do the same with him...'
},
  {
  img:four,
  review: 'Excellent work provided by seller , he build a react website for my company with extra ordinary functionalities. Highly recommended'
},
  {
  img:one,
  review: 'Effective, efficient and attentive. Helped me a lot to sort out problems within my React project Front End. Extremely recommended, and will work again with.'
},
]



const Testmonial = () => {
  return (
    <>
      <section className="testmonial" id='Testmonials'>
        <div className="testmonial-color">
          <span>Clients always get &nbsp;</span>
          <span>Exceptional Work &nbsp;</span>
          <span>from me...</span>
          <div className='blur t-blur1' style={{backgroung: 'var(--purple)'}}></div>
        <div className='blur t-blur2' style={{backgroung: 'skyblue'}}></div>
        </div>
        <Swiper 
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{clickable:true}}
        >
          {Clients.map((client, index) => {
            return(
              <SwiperSlide 
              >
                <div className='test'>
                <img src={client.img} alt="" />
                <span>{client.review}</span>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>

      </section>
    </>
  )
}

export default Testmonial
