import React from "react";
import "./Intro.css";
import Floating from "../FloatingDiv/Floating";

import github from "../../img/github.png";
import linkedin from "../../img/linkedin.png";
import instra from "../../img/instagram.png";

import vector1 from "../../img/Vector1.png";
import vector2 from "../../img/Vector2.png";
import boy from "../../img/boy.png";
import crown from "../../img/crown.png";
import thumb from "../../img/thumbup.png";
import emoji from "../../img/glassesimoji.png";
// import check from '../../sfv.gif'
import { Link } from "react-scroll";


import { themeContext } from "../../Context";
import { useContext } from "react";

import { motion } from "framer-motion";

const Intro = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;

  const transition ={ duration: 2, type: 'spring'}
  return (
    <>
      <section className="intro">
        <div className="intro-left">
          <div>
            <span
              className="hi"
              style={{
                background: darkMode ? "black" : "",
                color: darkMode ? "white" : "",
              }}
            >
              Hy! I Am
            </span>
            <span className="i-name">Muhammd Ahmad</span>
            <span
              className="i-detail"
              style={{
                background: darkMode ? "black" : "",
                color: darkMode ? "white" : "",
              }}
            >
              Frontend developer with high level of experience in web designing
              and development, producting the Quality work
            </span>
          </div>
          <Link spy={true} to='Contact' smooth={true} >
          <button className="btn i-btn">Hire me</button>
          </Link>
          <section className="i-pics">
         
          
          <a href="https://github.com/Ahmad4330">
          <img src={github} alt="" />
          </a>
          <a href="https://www.linkedin.com/in/muhammad-ahmad-059169234/">
          <img src={linkedin} alt="" />

          </a>
          <a href="/">
          <img src={instra} alt="" />
          </a>
    
          </section>
        </div>
        <div className="intro-right">
          <img src={vector1} alt="" />
          <img src={vector2} alt="" />
          <img src={boy} alt="" />
          {/* <img src={check} alt=""  style={{
            top: "-1rem",
            borderRadius: "10%",
          }}/> */}
          <motion.img 
          initial={{left : '-36%'}}
          whileInView={{left :'-24%'}}
          transition={transition}

          src={emoji} alt=""/>
          <motion.div
           initial={{left : '74%', top: '-4%'}}
           whileInView={{left :'68%'}}
           transition={transition}
          style={{ top: "-4%", left: "68%" }}>
            <Floating image={crown} text1="Web" text2="Developer" />
          </motion.div>
          <motion.div
             initial={{left : '9rem', top: '18rem'}}
             whileInView={{left :'0rem'}}
             transition={transition}
          style={{ top: "18rem", left: "0rem" }}>
            <Floating image={thumb} text1="Best Design" text2="Award" />
          </motion.div>

          {/* Blur Divs */}
          <div
            className="blur"
            style={{
              top: "-18%",
              left: "56%",
              background: "rgba(238, 210, 255)",
            }}
          ></div>
          <div
            className="blur"
            style={{
              top: "17rem",
              left: "-9rem",
              width: "21rem",
              height: "11rem",
              background: "#C1F5FF",
            }}
          ></div>
        </div>
      </section>
    </>
  );
};

export default Intro;
