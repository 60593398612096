import React from "react";
import "./Experience.css";

import { themeContext } from "../../Context";
import { useContext } from "react";

const Experience = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <>
      <section className="experience" id="Experience">
        <div className="achivement">
          <div
            className="circle"
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
              border: darkMode ? "5px solid orange" : "",

            }}
          >
            1+
          </div>
          <span>Years</span>
          <span>Experience</span>
        </div>
        <div className="achivement">
          <div
            className="circle"
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
              border: darkMode ? "5px solid orange" : "",
            }}
          >
            10+
          </div>
          <span>Completed</span>
          <span>Projects</span>
        </div>
        <div className="achivement">
          <div
            className="circle"
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
              border: darkMode ? "5px solid orange" : "",

            }}
          >
            3+
          </div>
          <span>Companies</span>
          <span>Work</span>
        </div>
      </section>
    </>
  );
};

export default Experience;
