import React from 'react'
// import { useState } from 'react';
import { BiMoon } from 'react-icons/bi';
import { FiSun } from 'react-icons/fi';
import { themeContext } from '../../Context';
import { useContext } from 'react';


const Toggle = () => {
  const theme = useContext(themeContext)
  const darkMode = theme.state.drakMode;

  const toggleHandler = () => {
    theme.dispatch({type:'toggle'})
  }
  return (
    <div className='toggle' onClick={toggleHandler}>
      <BiMoon/>
      <FiSun/>
      <div className='t-button'
      style={darkMode? {left : '2px'} : {right : '2px'}}
      >
      </div>
    </div>
  )
}

export default Toggle
