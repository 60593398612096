import React from "react";
import "./Services.css";
import Card from "../Card/Card";

import glasses from "../../img/glasses.png";
import heart from "../../img/heartemoji.png";
import humble from "../../img/humble.png";
import resume from "./aa.pdf";

import { themeContext } from "../../Context";
import { useContext } from "react";

const Services = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <>
      <section className="services" id="Services">
        <div className="services-left">
          <h2
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
            }}
          >
            My Awesome
          </h2>
          <h2>Services</h2>
          <p
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
            }}
          >
            I am a React Developer with experience in responsive
            web development, functionality <br/>implementation, API integration, bug
            fixing, and feature addition.
            
          </p>
          <a href={resume}>
            <button className="btn s-btn">Download Cv</button>
          </a>
          <div
            className="blur s-blur"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        <div className="services-right">
          <div className="checkk" style={{ left: "14rem" }}>
            <Card
              emoji={heart}
              heading="Design"
              detail="Figma, Stech, Photoshop, Adobe, Adobe xd"
            />
          </div>
          <div className="checkk2" style={{ top: "12rem", left: "-4rem" }}>
            <Card
              emoji={glasses}
              heading="Developer"
              detail="Html, Css, JavaScript, ReactJs"
            />
          </div>
          <div className="checkk3" style={{ top: "19rem", left: "12rem" }}>
            <Card
              emoji={humble}
              heading="UI/UX"
              detail="I will convert design into error less code and it is perfect for client"
            />
          </div>
          <div
            className="blur s-blur-2"
            style={{ backgroung: "var(--purple)" }}
          ></div>
        </div>
      </section>
    </>
  );
};

export default Services;
