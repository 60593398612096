import React from 'react'
import './Footer.css'
import wave from '../../img/wave.png'
import { MdFacebook } from 'react-icons/md';
import { BsGithub } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='footer'>
      <img src={wave} alt="" />
      <div className="icons">
      <a href="https://github.com/Ahmad4330">
      <MdFacebook/>
          </a>
      <a href="https://github.com/Ahmad4330">
      <BsGithub/>

          </a>
      <a href="https://github.com/Ahmad4330">
      <BsInstagram/>

          </a>
      </div>
      <span>Copyright © 2022 Ahmad Shoukat</span>
    </div>
  )
}

export default Footer
